import React from 'react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Services from '../Services/Services';
import Footer from '../Footer/Footer';
import About from '../About/About';
import './mainPage.css';


const MainPage = () => {
  return (
    <div className="main-page">
      <Header />
      <Hero />
      <Services />
      <About />
      <Footer />
    </div>
  );
};

export default MainPage;