import React from 'react';
import { FaCar, FaCogs, FaOilCan, FaTools, FaCog, FaSearch, FaWrench } from 'react-icons/fa';
import './Services.css';
import wheelPic from './wheel.png';
import wrench from './wrench.png';
const Services = () => {
  const services = [
    {
      id: 1,
      icon: <FaCar style={{color: '#FFD700'}}/>,
      title: 'Ремонт ходовой',
      description: 'Профессиональный ремонт подвески и ходовой части автомобиля'
    },
    {
      id: 2,
      icon: <FaCogs style={{color: '#FFD700'}}/>,
      title: 'Ремонт двигателей',
      description: 'Капитальный ремонт и обслуживание двигателей'
    },
    {
      id: 3,
      icon: <FaOilCan style={{color: '#FFD700'}}/>,
      title: 'Замена Масла',
      description: 'Замена масла и технических жидкостей'
    },
    {
      id: 4,
      icon: <FaTools style={{color: '#FFD700'}}/>,
      title: 'Замена Колодок, Замена ГРМ',
      description: 'Замена тормозных колодок и ремня ГРМ'
    },
    {
      id: 5,
      icon: <FaCog style={{color: '#FFD700'}}/>,
      title: 'Шиномонтаж',
      description: 'Профессиональный шиномонтаж и балансировка'
    },
    {
      id: 6,
      icon: <FaSearch style={{color: '#FFD700'}}/>,
      title: 'Диагностика',
      description: 'Компьютерная диагностика всех систем автомобиля'
    },
    {
      id: 7,
      icon: <FaWrench style={{color: '#FFD700'}}/>,
      title: 'Подбор запчастей',
      description: 'Помощь в подборе и заказе автозапчастей'
    }
  ];

  return (
    <section id="services" className="services">
        <img className='wrenchPic' src={wrench} alt="wrench" />
      <div className="container">
        <h2 className="services__title">Наши услуги</h2>
        <div className="services__grid">
          {services.map((service) => (
            <div key={service.id} className="service-card">
              <div className="service-card__icon">
                {service.icon}
              </div>
              <h3 className="service-card__title">{service.title}</h3>
              <p className="service-card__description">{service.description}</p>
            </div>
          ))}
          <img className='wheelPic' src={wheelPic} alt="wheel" />
        </div>
      </div>
    </section>
  );
};

export default Services;