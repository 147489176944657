import React from 'react';
import { FaTools, FaUserFriends, FaClock, FaCheckCircle } from 'react-icons/fa';
import './About.css';

const About = () => {
  const advantages = [

    {
      icon: <FaUserFriends />,
      title: 'Опытные мастера',
      description: 'Команда квалифицированных специалистов с опытом более 10 лет'
    },
    {
      icon: <FaClock />,
      title: 'Быстрый сервис',
      description: 'Выполняем работы качественно и в кратчайшие сроки'
    },
    {
      icon: <FaCheckCircle />,
      title: 'Гарантия качества',
      description: 'Предоставляем гарантию на все виды выполненных работ'
    }
  ];

  return (
    <section id="about" className="about">
      <div className="container">
        <h2 className="about__title">О нас</h2>
        
        <div className="about__content">
          <div className="about__text">
            <p>
              Наш автосервис предоставляет полный спектр услуг по ремонту и обслуживанию автомобилей. 
              Мы работаем с 2010 года и за это время заслужили доверие клиентов.
            </p>
            <p>
              Мы специализируемся на диагностике, ремонте и техническом обслуживании автомобилей 
              всех марок. Наши мастера регулярно проходят обучение и повышают квалификацию.
            </p>
          </div>
        </div>

        <div className="about__advantages">
          {advantages.map((item, index) => (
            <div key={index} className="advantage-card">
              <div className="advantage-card__icon">
                {item.icon}
              </div>
              <h3 className="advantage-card__title">{item.title}</h3>
              <p className="advantage-card__description">{item.description}</p>
            </div>
          ))}
        </div>

        <div className="about__stats">
          <div className="stat-item">
            <h3>10+</h3>
            <p>Лет опыта</p>
          </div>
          <div className="stat-item">
            <h3>100+</h3>
            <p>Довольных клиентов</p>
          </div>
          <div className="stat-item">
            <h3>100%</h3>
            <p>Гарантия качества</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;