import React, { useState } from 'react';
import './Hero.css';
import carPic from "./car.png";
import { FaPhone, FaMapMarkerAlt, FaBars, FaTimes } from 'react-icons/fa'; 

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    description: ''
  });
  const handleButtonClick = (e) => {
    e.preventDefault(); 
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://api.xn----7sbecarwztdftlei4t.xn--p1ai/api/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          phone: formData.phone,
          problem: formData.description 
        })
      });
  
      if (response.ok) {
        setFormData({ name: '', phone: '', description: '' });
        setIsModalOpen(false);
        alert('Заявка успешно отправлена!');
      } else {
        throw new Error('Ошибка при отправке формы');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Произошла ошибка при отправке формы. Попробуйте еще раз.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <section className="hero">
      <div className="container hero__container">
        <div className="hero__content">
          <h1>Профессиональный автосервис</h1>
          <p>Качественный ремонт и обслуживание вашего автомобиля</p>
          <img className='carPic' src={carPic} alt="car" />
          <button 
            className="hero__button"
            onClick={handleButtonClick}
            type="button" 
          >
            Записаться на ремонт
          </button>
          <div className="header__phone_small">
          <div className="contact-item">
            <FaPhone className="contact-icon" />
            <a href="tel:+79261507115">+7 (926) 150-71-15</a>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <a 
              href="https://yandex.ru/maps/21641/lobnja/house/nauchny_gorodok_9a/Z04Ycg9hSkAFQFtsfXx1d31nZg==/?ll=37.481165%2C56.045918&z=16.55" // здесь вставите вашу ссылку на навигатор
              className='address' 
              target="_blank" 
              rel="noopener noreferrer"
              style={{fontSize:"14px"}}
            >
              г. Лобня, ул. Научный городок, 9A
            </a>
          </div>
        </div>
        </div>
      </div>

      {/* Модальное окно */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal" onClick={e => e.stopPropagation()}>
            <button 
              className="modal__close"
              onClick={() => setIsModalOpen(false)}
            >
              ×
            </button>
            <h2>Запись на ремонт</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Ваше имя</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Введите ваше имя"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Номер телефона</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="+7 (___) ___-__-__"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Описание проблемы</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
              
                  required
                  placeholder="Кратко опишите проблему"
                />
              </div>
              <button type="submit" className="modal__submit">
                Отправить заявку
              </button>
             
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;